var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.loading),expression:"loading",modifiers:{"fullscreen":true,"lock":true}}],staticClass:"naire-list"},[_c('div',{staticClass:"naire-btn"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.createNaire}},[_vm._v("创建问卷")])],1),_c('el-table',{attrs:{"data":_vm.list},on:{"selection-change":_vm.onSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_c('el-table-column',{attrs:{"prop":"n_title","label":"问卷名称","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"tag":"a","to":("./view/" + (row.n_id))}},[_vm._v(" "+_vm._s(row.n_title)+" "),(_vm.isExpired(row.n_deadline))?_c('el-tag',{staticClass:"ml-10",attrs:{"size":"mini","type":"danger"}},[_vm._v("已截止")]):_vm._e()],1)]}}])}),_c('el-table-column',{attrs:{"prop":"n_creattime","label":"创建时间","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatTime")(row.n_creattime))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"n_deadline","label":"截止时间","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatTime")(row.n_deadline))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"n_status","label":"发布状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{attrs:{"type":_vm._f("statusColorFilter")(row.n_status)}},[_vm._v(" "+_vm._s(_vm._f("statusFilter")(row.n_status))+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-dropdown',{on:{"command":function($event){return _vm.onOptionClick($event, row)}}},[_c('el-button',{attrs:{"type":"danger","size":"mini"}},[_vm._v(" 操作"),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"edit","divided":""}},[_vm._v("编辑问卷")]),_c('el-dropdown-item',{attrs:{"command":"deadline"}},[_vm._v("编辑截止时间")]),_c('el-dropdown-item',{attrs:{"command":"publish"}},[_vm._v(_vm._s(row.n_status === _vm.NaireStatus.PUBLISHED ? '停止发布' : '发布问卷'))]),_c('el-dropdown-item',{attrs:{"command":"delete"}},[_vm._v("删除问卷")])],1)],1)]}}])})],1),_c('change-time',{attrs:{"visible":_vm.changeTimeVisible,"model":_vm.editModel},on:{"update:visible":function($event){_vm.changeTimeVisible=$event}}}),_c('copy-url',{attrs:{"visible":_vm.copyUrlVisible,"model":_vm.editModel},on:{"update:visible":function($event){_vm.copyUrlVisible=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }