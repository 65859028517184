































































import { Component, Vue } from 'vue-property-decorator'
import dayjs from 'dayjs'
import ChangeTime from './components/ChangeTime.vue'
import CopyUrl from './components/CopyUrl.vue'
import * as NaireAction from '@/api/naire'
import { IApiNaireItem } from '@/api/types'

import { NaireStatus, NaireStatusText, NaireStatusColor } from '@/config/enum/naireStatus'

@Component({
  filters: {
    formatTime (val: string | number) {
      const timestamp = Number(val)
      return dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss')
    },
    statusFilter (val: NaireStatus) {
      return NaireStatusText[val]
    },
    statusColorFilter (val: NaireStatus) {
      return NaireStatusColor[val]
    }
  },
  components: {
    ChangeTime,
    CopyUrl
  }
})
export default class NavBar extends Vue {
  private list: IApiNaireItem[] = []
  private NaireStatus = NaireStatus
  private loading: boolean = false
  private changeTimeVisible: boolean = false
  private copyUrlVisible: boolean = false
  private editModel: any = {}
  private selectContent: any[] = []

  mounted () {
    this.fetchListData()
  }

  onSelectionChange (val: any) {
    this.selectContent = val
  }

  onOptionClick (command: string, row: any) {
    switch (command) {
      case 'preview':
        this.$router.push({
          name: 'view',
          params: {
            id: row.n_id
          }
        })
        break
      case 'copyUrl':
        this.editModel = row
        this.copyUrlVisible = true
        break
      case 'submitStatistic':
        this.$router.push({
          name: 'submitStatistics',
          params: {
            id: row.n_id
          }
        })
        break
      case 'edit':
        this.$router.push({
          name: 'edit',
          params: {
            id: row.n_id
          }
        })
        break
      case 'publish':
        this.changeStatus(row)
        break
      case 'deadline':
        this.editModel = row
        this.changeTimeVisible = true
        break
      case 'delete':
        this.singleDelete(row)
        break
    }
  }

  /**
   * 是否已截止
   * @param deadline
   */
  public isExpired (deadline: number) {
    return deadline < Date.now()
  }

  public createNaire () {
    this.$router.push({ name: 'create' })
  }

  private async deleteNaire (nIds: string) {
    const res = await NaireAction.del({
      n_id: nIds
    })
    if (res.success) {
      this.$message.success('删除成功')
      this.fetchListData()
    } else {
      this.$message.error('删除失败')
    }
  }

  public singleDelete (row: IApiNaireItem) {
    this.$confirm('您确认删除问卷吗？', '删除', {
      type: 'warning'
    })
      .then(async () => {
        this.deleteNaire(row.n_id)
      })
      .catch(() => {})
  }

  public batchDelete () {
    this.$confirm('您确认删除这几条内容吗？', '批量删除', {
      type: 'warning'
    })
      .then(async () => {
        const rowIds = this.selectContent.map(({ n_id: id }) => id).join(',')
        this.deleteNaire(rowIds)
      })
      .catch(() => {})
  }

  /**
   * 查看统计
   * @param row
   */
  public handleStatistics (row: IApiNaireItem) {
    this.$router.push({
      name: 'statisticsResult',
      params: {
        id: row.n_id
      }
    })
  }

  /**
   * 修改发布状态
   * @param row
   */
  async changeStatus (row: IApiNaireItem) {
    this.loading = true
    const res = await NaireAction.changeStatus({
      n_id: row.n_id
    })
    this.loading = false
    if (res.success) {
      this.$message.success('更改状态成功')
      this.fetchListData()
    } else {
      this.$message.error(res.msg)
    }
  }

  public async fetchListData () {
    this.loading = true
    const res = await NaireAction.list()
    this.loading = false
    if (!res.success) return
    this.list = res.data ? res.data : []
  }
}
